import type { LoaderFunctionArgs } from '@remix-run/node';
import { json } from '@remix-run/node';
import { Outlet } from '@remix-run/react';
import { twc } from 'react-twc';

import { isInitialized } from '~/server/is-initialized';

export const loader = async ({ context }: LoaderFunctionArgs) => {
  await isInitialized(context);

  return json({});
};

const Main = twc.div`m-2 flex grow flex-col`;
export default function NoAuthLayout() {
  return (
    <Main>
      <Outlet />
    </Main>
  );
}
